<template>
  <v-container fluid>
    <v-card-title>투명라벨 관리</v-card-title>
    <v-card-subtitle class="py-0 pt-3">
      *추가 : 추가하고 싶은 투명라벨을 text box에 적고, 추가 버튼을 누르면 라벨이 추가됩니다.
    </v-card-subtitle>
    <v-card-subtitle class="py-0">
      *수정 : 투명라벨별로 텍스트를 수정할 수 있습니다.
    </v-card-subtitle>
    <v-card-subtitle class="py-0 pl-15">
      텍스트가 수정되면 해당 투명라벨이 적용된 모든 추천답변에서도 함께 텍스트가 변경됩니다.
    </v-card-subtitle>
    <v-card-subtitle class="py-0">
      *삭제 : 투명라벨별로 삭제가 가능하며, 삭제 후에는 되돌릴 수 없습니다.
    </v-card-subtitle>
    <v-row class="pa-4" no-gutters>
      <v-col cols="3">
        <v-text-field v-model="name" color="#ffd600" outlined placeholder="추가할 투명라벨을 입력하세요" hide-details dense @keyup.enter="add" />
      </v-col>
      <v-col cols="1" class="mx-3">
        <v-btn color="#ffd600" light depressed class="pa-5" @click="add">
          추가
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          color="deep-purple accent-2"
          outlined
          placeholder="검색하고 싶은 투명라벨을 입력하세요"
          hide-details
          dense
          @keyup="searchTags(false)"
        />
      </v-col>
      <v-col cols="1" class="mx-3">
        <v-btn dark color="deep-purple accent-2" depressed class="pa-5" @click="searchTags(true)">
          검색
        </v-btn>
      </v-col>
      <v-col>
        <input ref="csvRef" type="file" class="d-none" @input="upload" />
        <v-btn dark color="blue" depressed class="pa-5" @click="$refs.csvRef.click()">
          불러오기
        </v-btn>
        <v-btn dark color="red" depressed class="pa-5 ml-3" @click="download">
          내보내기
        </v-btn>
      </v-col>
    </v-row>
    <v-card outlined flat>
      <v-row no-gutters>
        <v-card-subtitle class="font-weight-bold ml-2 pb-0">
          Number
        </v-card-subtitle>
        <v-spacer />
        <v-card-subtitle class="font-weight-bold ml-2 pb-0">{{ tags.length }}개</v-card-subtitle>
      </v-row>
      <v-card-text class="pb-0">
        <v-btn v-for="(tag, i) in number" :key="i" :color="tag.active ? 'green' : 'orange accent-2'" depressed outlined class="pa-1 pl-2 ma-2">
          <input
            ref="numberRef"
            v-autowidth="{ comfortZone: 16 }"
            class="font-weight-bold"
            type="text"
            :style="`outline: none; color: ${$vuetify.theme.dark ? 'white' : ''}`"
            :disabled="!tag.active"
            spellcheck="false"
            :value="tag.name"
            @input="tag.name = $event.target.value"
            @keyup.enter="edit(tag, i, 'numberRef')"
          />
          <v-icon class="mr-1" @click="edit(tag, i, 'numberRef')">
            {{ tag.active ? 'mdi-check-circle-outline' : 'mdi-pencil-outline' }}
          </v-icon>
          <v-icon :color="tag.active ? 'orange' : 'grey darken-2'" @click="close(tag, tags)">
            {{ tag.active ? 'mdi-close-circle-outline' : 'mdi-delete-outline' }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-subtitle v-if="korean" class="font-weight-bold ml-2">
        Korean
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-btn v-for="(tag, i) in korean" :key="i" :color="tag.active ? 'green' : 'blue'" depressed outlined class="pa-1 pl-2 ma-2">
          <input
            ref="koreanRef"
            v-autowidth="{ comfortZone: 16 }"
            class="font-weight-bold"
            type="text"
            :style="`outline: none; color: ${$vuetify.theme.dark ? 'white' : ''}`"
            :disabled="!tag.active"
            spellcheck="false"
            :value="tag.name"
            @input="tag.name = $event.target.value"
            @keyup.enter="edit(tag, i, 'koreanRef')"
          />
          <v-icon class="mr-1" @click="edit(tag, i, 'koreanRef')">
            {{ tag.active ? 'mdi-check-circle-outline' : 'mdi-pencil-outline' }}
          </v-icon>
          <v-icon :color="tag.active ? 'orange' : 'grey darken-2'" @click="close(tag, tags)">
            {{ tag.active ? 'mdi-close-circle-outline' : 'mdi-delete-outline' }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-subtitle v-if="english" class="font-weight-bold ml-2">
        English
      </v-card-subtitle>
      <v-card-text class="pb-0">
        <v-btn v-for="(tag, i) in english" :key="i" :color="tag.active ? 'green' : 'deep-purple accent-1'" depressed outlined class="pa-1 pl-2 ma-2">
          <input
            ref="englishRef"
            v-autowidth="{ comfortZone: 16 }"
            class="font-weight-bold"
            type="text"
            :style="`outline: none; color: ${$vuetify.theme.dark ? 'white' : ''}`"
            :disabled="!tag.active"
            spellcheck="false"
            :value="tag.name"
            @input="tag.name = $event.target.value"
            @keyup.enter="edit(tag, i, 'englishRef')"
          />
          <v-icon class="mr-1" @click="edit(tag, i, 'englishRef')">
            {{ tag.active ? 'mdi-check-circle-outline' : 'mdi-pencil-outline' }}
          </v-icon>
          <v-icon :color="tag.active ? 'orange' : 'grey darken-2'" @click="close(tag, tags)">
            {{ tag.active ? 'mdi-close-circle-outline' : 'mdi-delete-outline' }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-subtitle v-if="other" class="font-weight-bold ml-2">
        Other
      </v-card-subtitle>
      <v-card-text>
        <v-btn v-for="(tag, i) in other" :key="i" :color="tag.active ? 'green' : null" depressed outlined class="pa-1 pl-2 ma-2">
          <input
            ref="otherRef"
            v-autowidth="{ comfortZone: 16 }"
            class="font-weight-bold"
            type="text"
            :style="`outline: none; color: ${$vuetify.theme.dark ? 'white' : ''}`"
            :disabled="!tag.active"
            spellcheck="false"
            :value="tag.name"
            @input="tag.name = $event.target.value"
            @keyup.enter="edit(tag, i, 'otherRef')"
          />
          <v-icon class="mr-1" @click="edit(tag, i, 'otherRef')">
            {{ tag.active ? 'mdi-check-circle-outline' : 'mdi-pencil-outline' }}
          </v-icon>
          <v-icon :color="tag.active ? 'orange' : 'grey darken-2'" @click="close(tag, tags)">
            {{ tag.active ? 'mdi-close-circle-outline' : 'mdi-delete-outline' }}
          </v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
    <span class="d-none">{{ update }}</span>
  </v-container>
</template>

<script>
import { onBeforeMount, reactive, watch, toRefs, nextTick, defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      name: '',
      search: '',
      tags: [],
      originTags: [],
      number: [],
      korean: [],
      english: [],
      other: [],
      update: 0,
    })

    const refs = reactive({
      csvRef: null,
      numberRef: null,
      koreanRef: null,
      englishRef: null,
      otherRef: null,
    })

    const init = async () => {
      state.tags = await root.$store.dispatch('stamp/getListInvisibleStamp')
      state.originTags = state.tags
    }

    const searchTags = async (setup) => {
      if (setup) await init()
      else state.tags = state.originTags
      state.tags = state.tags.filter((e) => e.name.includes(state.search))
    }

    const add = async () => {
      if (!state.name) return alert('추가할 투명라벨을 입력해주세요')

      if (!confirm('투명라벨을 추가하시겠습니까?')) return

      try {
        const tag = await root.$store.dispatch('stamp/createInvisibleStamp', {
          name: state.name,
        })

        state.tags.push(tag)
        state.name = ''
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    const edit = async (tag, i, ref) => {
      try {
        if (tag.active) {
          tag.active = false
          await root.$store.dispatch('stamp/updateInvisibleStamp', {
            id: Number(tag.id),
            name: tag.name,
          })
        } else {
          tag.lastname = tag.name
          tag.active = true

          nextTick(() => {
            const input = refs[ref][i]
            const length = input.value.length
            input.selectionStart = length
            input.selectionEnd = length
            input.focus()
          })
        }

        state.update++
      } catch (err) {
        alert(err)
        console.error(err)
      }
    }

    const close = async (tag, tags) => {
      try {
        if (tag.active) {
          tag.name = tag.lastname
          tag.active = !tag.active
        } else if (confirm('정말 삭제하시겠습니까?')) {
          await root.$store.dispatch('stamp/deleteInvisibleStamp', {
            id: Number(tag.id),
          })
          tags.splice(tags.indexOf(tag), 1)
        }

        state.update++
      } catch (error) {
        alert(error)
        console.error(error)
      }
    }

    const upload = async (e) => {
      const file = e.target.files[0]
      refs.csvRef.value = null

      const data = await new Promise((resolve) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          resolve(e.target.result)
        }
        fileReader.readAsText(file)
      })

      const line = data.split('\n')
      line.splice(0, 1)

      for (const e of line) {
        const row = e.split(',')

        if (row[0] === '' && row[1])
          await root.$store.dispatch('stamp/createInvisibleStamp', {
            name: row[1],
          })
        else if (
          !row[2] &&
          state.tags.filter((e) => {
            return e.id === row[0] && e.name !== row[1]
          })[0]
        )
          await root.$store.dispatch('stamp/updateInvisibleStamp', {
            id: Number(row[0]),
            name: row[1],
          })
        else if (
          row[2] &&
          state.tags.filter((e) => {
            return e.id === row[0] && e.name === row[1]
          })[0]
        )
          await root.$store.dispatch('stamp/deleteInvisibleStamp', {
            id: Number(row[0]),
          })
      }

      init()
      alert('불러오기 완료!')
    }

    const download = () => {
      let csv = '\uFEFFID,Name,Remove\n'

      state.tags.forEach((e) => {
        csv += `${e.id},${e.name}\n`
      })

      const downloadLink = document.createElement('a')
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
      const url = URL.createObjectURL(blob)
      downloadLink.href = url
      downloadLink.download = '투명라벨.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }

    onBeforeMount(() => {
      init()
    })

    watch(
      () => state.tags,
      () => {
        state.number = state.tags.filter((v) => v.name[0].replace(/[^0-9]/gi, ''))
        state.number.sort((a, b) => {
          return Number(a.name[0]) - Number(b.name[0])
        })
        state.korean = state.tags.filter((v) => v.name[0].replace(/[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/gi, ''))
        state.english = state.tags.filter((v) => v.name[0].replace(/[^a-z]/gi, ''))
        state.other = state.tags.filter((v) => v.name[0].replace(/[0-9]|[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]|[a-z]/gi, ''))
      },
    )

    return {
      ...toRefs(state),
      ...toRefs(refs),
      init,
      searchTags,
      add,
      edit,
      close,
      upload,
      download,
    }
  },
})
</script>
